<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        collection: 'onboarding',
        actions: [
          {
            text: 'Resend invitation',
            body: 'resend-invitation',
            message: 'resend',
          },
        ],
        headers: [
          { text: 'Recipient email', value: 'email', filter: true, width: '400px' },
          {
            text: 'Profile',
            value: 'account',
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
          {
            text: 'Role',
            value: 'role',
            component: {
              name: 'dtView',
              props: { name: 'role', permission: 'role', getter: item => this.$toEdit('role', item.role._id) },
            },
          },
          { text: 'Status', value: 'status', capitalize: true },
          { text: 'Sender email ', value: 'createdBy.email', width: '400px' },
        ],
        header: {
          created: 'Sent',
        },
        hideHeader: {
          updated: true,
        },
        hideAction: true,
      },
    };
  },
};
</script>
